






import { 
  defineComponent,
  useContext,
  useRouter,
  onMounted,
  ref,
  getCurrentInstance,
} from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import { useApi } from '~/composables/useApi';
import PaymentLoader from '~/modules/fortytwo/payment/components/PaymentLoader.vue';
import { usePaymentStore } from '~/modules/fortytwo/payment/stores/payment';
export default defineComponent({
  name: "Cancel",
  // using the empty layout.
  layout: 'empty',
  components: {
    PaymentLoader,
  },
  async asyncData(context) {
  return {
    bodyData: context["req"]["body"],
  }
  },
  setup(){

    const { app, $config, app:{i18n} } = useContext();
    const storeUrl = $config.storeUrl;
    const paymentLoadingMessage = i18n.t("Redirecting you back to {storeURL}. Please wait...", {storeURL: storeUrl});
    const paymentStore = usePaymentStore();
    onMounted(async () => {
      const { query } = useApi();
      const router = useRouter();
      const cybsComplete:any = ref({});
      const form = getCurrentInstance().data.bodyData;  
      console.log('post data');
      console.log(form);
      const myGraphqlQuery = `
      mutation {
          cybsCancel(input: {
            order_id: "${form["req_reference_number"]}"
            merchant_defined_data1: "${form["req_merchant_defined_data1"]}"
          }) {
            error
            message
          }
        }
      `; 
      
      const fetchCybsCompleteExtendedData = async () => {
        const { data } = await query(myGraphqlQuery);
        cybsComplete.value = await merge({}, cybsComplete.value, data);

        console.log('Welcome back!');
        console.log(cybsComplete);
        if(cybsComplete?.value?.cybsResponse?.message){
          await paymentStore.setMessage(cybsComplete?.value?.cybsResponse?.message);
        }
        const paymentRoute = app.localeRoute({
            name: "cart",
          });
          await router.push(paymentRoute);
      };
       await Promise.all([fetchCybsCompleteExtendedData()]);
    });

    return{
      paymentLoadingMessage,
    }

  }
});
